<template>
  <div class="roles">
    <div class="roles-header">
      <div class="roles-header-title">
        <div
          class="roles-header-title__back"
          @click="$router.push('/')"
        >
          <v-icon color="#D6D6D6">mdi-chevron-left</v-icon>
        </div>
        <div class="roles-header-title__heading">Роли</div>
      </div>

      <div class="roles-header-add">
        <v-btn
          v-if="hasRoleAccess(permissionsRoutes.roles.roleCreate)"
          color="black"
          dark
          class="radius-root"
          x-large
          @click="$router.push('/roles/create')"
        >
          Добавить роль
        </v-btn>
      </div>
    </div>
    <div class="roles-search">
      <v-text-field
        label="Введите название роли"
        prepend-inner-icon="mdi-magnify"
        filled
        dense
        v-model.trim="search"
        class="rounded-lg roles-search__input"
        rounded
        hide-details
        height="50"
      ></v-text-field>
    </div>
    <RolesList :roles="rolesItems" :rolesCount="roles.length" :search="search" />
    <ApproveDeleteModal @closeModal="closeModals()" :role="currentRole" :showModal="showApproveDeleteModal" />
    <DeleteErrorModal @closeModal="closeModals()" :role="currentRole" :showModal="showDeleteErrorModal" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import notification from '@/mixins/notification';
import RolesList from './components/RolesList.vue';
import ApproveDeleteModal from './components/ApproveDeleteModal.vue';
import DeleteErrorModal from './components/DeleteErrorModal.vue';

export default {
  name: 'RolesMain',
  components: {
    RolesList,
    ApproveDeleteModal,
    DeleteErrorModal,
  },
  mixins: [notification],

  data() {
    return {
      currentRole: null,
      showApproveDeleteModal: false,
      showDeleteErrorModal: false,
      search: '',
    };
  },

  async created() {
    await this.getRoles();
    this.$emitter.$on('roles/approveDeleteModal', () => {
      this.showApproveDeleteModal = !this.showApproveDeleteModal;
    });

    this.$emitter.$on('roles/deleteErrorModal', () => {
      this.showDeleteErrorModal = !this.showDeleteErrorModal;
    });

    this.$emitter.$on('roles/setCurrentRole', (role) => {
      this.currentRole = role;
    });
  },

  computed: {
    ...mapState('roles', ['roles']),
    ...mapGetters({
      rolesProgress: 'roles/fetchProgress',
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    rolesItems() {
      if (this.search === '') return this.roles;
      const searchValue = this.search.toLowerCase();
      return this.roles.filter((r) => r.name.toLowerCase().includes(searchValue));
    },
  },

  methods: {
    ...mapActions('roles', ['getRoles']),
    closeModals() {
      this.showApproveDeleteModal = false;
      this.showDeleteErrorModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
