<template>
  <div class="roles-list">
    <table class="roles-list-table">
      <thead>
        <tr>
          <th
            class="roles-list-table__sort"
            @click="setSort('name')"
          >
            <span class="mr-1">Название роли</span>
            <v-icon
              v-if="(sortBy.field === 'name' && sortBy.mode === 'asc') || sortBy.field !== 'name'"
              dense
              color="#aaaaaa"
            >
              mdi-sort-alphabetical-ascending-variant
            </v-icon>
            <v-icon v-if="sortBy.field === 'name' && sortBy.mode === 'desc'" dense color="#aaaaaa">mdi-sort-alphabetical-descending-variant</v-icon>
          </th>
          <th
            :class="{ 'roles-list-table__sort--active': sortBy.field === 'status' && sortBy.mode !== 'desc' }"
            class="roles-list-table__sort"
            @click="setSort('status')"
          >
            <span class="mr-1">Статус</span>
            <v-icon dense color="#aaaaaa">mdi-sort</v-icon>
          </th>
          <th
            :class="{ 'roles-list-table__sort--active': sortBy.field === 'qty' && sortBy.mode !== 'desc' }"
            class="roles-list-table__sort"
            @click="setSort('qty')"
          >
            <span class="mr-1">Количество</span>
            <v-icon dense color="#aaaaaa">mdi-sort</v-icon>
          </th>
          <th class="roles-list-table__sort roles-list-table__sort--disabled">
            <span>Действия</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <RoleItem v-for="role in sortedRoles" :role-data="role" :key="role.id" />
      </tbody>
    </table>
    <NoResultsTable :searchText="searchText" :results="sortedRoles" :listCount="rolesCount" />
  </div>
</template>

<script>
import _ from 'lodash';
import RoleItem from './RoleItem.vue';
import NoResultsTable from '@/components/UI/NoResultsTable.vue';

export default {
  title: 'RolesList',
  components: { RoleItem, NoResultsTable },
  props: {
    roles: {
      type: Array,
    },
    search: {
      type: String,
    },
    rolesCount: {
      type: Number,
    },
  },

  data() {
    return {
      sortBy: {
        field: '',
        mode: 'asc',
      },
      sortedRoles: [],
    };
  },

  created() {
    this.sortedRoles = _.cloneDeep(this.roles);
  },

  computed: {
    searchText() {
      return this.search.length < 100 ? this.search : `${this.search.substring(0, 97)}...`;
    },
  },

  methods: {
    setSort(sort) {
      if (this.roles.length == 0) return;
      if (sort !== this.sortBy.field) {
        this.sortBy.field = sort;
        this.sortBy.mode = this.getNextSortMode('asc');
      } else {
        this.sortBy.mode = this.getNextSortMode(this.sortBy.mode);
      }
    },

    getNextSortMode(mode) {
      if (mode === 'asc') {
        return 'desc';
      }
      return 'asc';
    },
  },

  watch: {
    roles() {
      this.sortedRoles = _.cloneDeep(this.roles);
    },
    sortBy: {
      deep: true,
      handler() {
        const roles = _.cloneDeep(this.roles);
        if (this.sortBy.mode === null) {
          this.sortedRoles = roles;
          return;
        }
        if (this.sortBy.field === 'status') {
          if (this.sortBy.mode === 'asc') {
            this.sortedRoles = roles.sort((a, b) => a.active - b.active);
          } else {
            this.sortedRoles = roles.sort((a, b) => b.active - a.active);
          }
          return;
        }
        if (this.sortBy.field === 'qty') {
          if (this.sortBy.mode === 'asc') {
            this.sortedRoles = roles.sort((a, b) => a.emp_count - b.emp_count);
          } else {
            this.sortedRoles = roles.sort((a, b) => b.emp_count - a.emp_count);
          }
          return;
        }
        if (this.sortBy.field === 'name') {
          if (this.sortBy.mode === 'asc') {
            this.sortedRoles = _.orderBy(roles, [(role) => role.name.toLowerCase()], ['asc']);
          } else {
            this.sortedRoles = _.orderBy(roles, [(role) => role.name.toLowerCase()], ['desc']);
          }
          return;
        }
        this.sortedRoles = roles;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
