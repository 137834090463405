<template>
  <tr class="roles-list-table-item">
    <td class="roles-list-table-item__name"><div>{{ roleData.name }}</div></td>
    <td class="roles-list-table-item__status">
      <div>
        <span>Статус роли</span>
        <span v-if="roleData.active" class="roles-list-table-item__indicator roles-list-table-item__indicator--active">Активный</span>
        <span v-else class="roles-list-table-item__indicator">Неактивный</span>
      </div>
    </td>
    <td class="roles-list-table-item__qty">
      <div>
        <span>Количество сотрудников</span>
        <a @click="setFilter">{{ roleData.emp_count }}</a>
      </div>
    </td>
    <td class="roles-list-table-item__actions">
      <div>
        <v-btn v-if="hasRoleAccess(permissionsRoutes.roles.roleEdit) && roleData.name !== 'Member'"
        class="edit-project__close" icon plain @click="openRole">
          <v-icon color="#aaaaaa">mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="hasRoleAccess(permissionsRoutes.roles.roleEdit) && roleData.name !== 'Member'"
        class="edit-project__close" icon plain @click="deleteRole">
          <v-icon color="#aaaaaa">mdi-delete</v-icon>
        </v-btn>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RoleItem',
  props: {
    roleData: {
      type: Object,
    },
  },

  methods: {
    ...mapActions('roles', ['getRole']),

    deleteRole() {
      this.$emitter.$emit('roles/setCurrentRole', this.roleData);
      if (this.roleData.emp_count > 0) {
        this.$emitter.$emit('roles/deleteErrorModal');
      } else {
        this.$emitter.$emit('roles/approveDeleteModal');
      }
    },

    setFilter() {
      localStorage.setItem('sortByRole', this.roleData.id);
      this.$router.push('/staff/employees');
    },

    async openRole() {
      try {
        await this.getRole(this.roleData.id);
        this.$router.push(`/roles/edit/${this.roleData.id}`);
      } catch (error) {
        //
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables.scss";

</style>
